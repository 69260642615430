import { i18nextConfig } from "@cyna/common/lib/i18n"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"
import ICU from "i18next-icu"
import { initReactI18next } from "react-i18next"

void i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ICU)
  .use(HttpApi)
  .init({
    ...i18nextConfig,
    react: {
      transSupportBasicHtmlNodes: false,
    },
  })
