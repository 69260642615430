import { commonConfig } from "@cyna/common/config"
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "@cyna/common/constants"
import { BackendModule, InitOptions, ResourceKey } from "i18next"

export const i18nBackendPlugin: BackendModule = {
  type: "backend",
  init() {
    // Empty
  },
  read(language, namespace, callback) {
    void import(`@cyna/web/root/public/i18n/${language}/${namespace}.json`, {
      with: { type: "json" },
    }).then((r: { default: ResourceKey }) => {
      callback(null, r.default)
    })
  },
}

const isSSR = typeof window === "undefined"

export const i18nextConfig: InitOptions = {
  backend: { loadPath: "/i18n/{{lng}}/{{ns}}.json" },
  cleanCode: true,
  defaultNS: "common",
  detection: { order: ["path", "htmlTag", "cookie", "navigator"] },
  fallbackLng: DEFAULT_LANGUAGE,
  fallbackNS: "common",
  interpolation: {
    escapeValue: false,
  },
  // eslint-disable-next-line no-undefined
  lng: commonConfig.env.isTest ? DEFAULT_LANGUAGE : undefined,
  ns: [
    "apiKeys",
    "clients",
    "common",
    "dashboard",
    "integrations",
    "public",
    "reports",
    "settings",
  ],
  preload: isSSR ? SUPPORTED_LANGUAGES : [],
  supportedLngs: SUPPORTED_LANGUAGES,
}
